<!--
 * @Description: 发票支付信息列表 invoicePayList
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-07 11:36:14
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="开票支付记录"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-checkbox-group>
        <van-row class="list"
                 v-for="(item,index) in payRecordList"
                 :key=index>
          <van-col :span="1">
          </van-col>
          <van-col :span="23">
            <van-row style="margin-top:10px;font-size:15px;color: #333333;">
              <van-image width="15"
                         height="15"
                         :src="require('@/assets/wxInvoice/shijian.png')" />
              <span>{{item.paymentTime}}</span>
            </van-row>
            <van-row style="margin-top:15px;font-size: 15px;color: #666666;">
              <van-col :span="17">
                支付方式：{{item.payMethod}}
              </van-col>
              <van-col style="font-size: 21px;color: #333333;text-align:right"
                       :span="5">
                {{item.paymentMoneyAmount}}
              </van-col>
              <van-col style="font-size: 17px;color: #666666;"
                       :span="1">元</van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-checkbox-group>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      payRecordList: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // 获取支付订单
    this.payRecordList = this.$route.query.payRecordList
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 返回上一页
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    margin-top: 10px;
    .list {
      width: 100%;
      height: 79px;
      background-color: #ffffff;
      border-bottom: 1px solid #e7e8e8;
    }
  }
}
</style>
